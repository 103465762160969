import React, { useState, useEffect } from "react";
import {
	Box,
	Tabs,
	Tab,
	Select,
	MenuItem,
	Button,
	Typography,
	Grid,
	FormControl,
	InputLabel,
} from "@mui/material";
import Editor from "@monaco-editor/react";
import useHttpService from "../../customHooks/useHttpService";

function ApiTester() {
	const { getRelayEndpoints, getApiTypes, relayAPI } = useHttpService();

	const [apiTypes, setApiTypes] = useState([]);
	const [selectedType, setSelectedType] = useState("");

	const [apiEndpoints, setApiEndpoints] = useState([]);
	const [filteredEndpoints, setFilteredEndpoints] = useState([]);
	const [selectedApi, setSelectedApi] = useState(null);

	const [requestTab, setRequestTab] = useState(0);
	const [responseTab, setResponseTab] = useState(0);

	const [requestHeaders, setRequestHeaders] = useState("{}");
	const [requestBody, setRequestBody] = useState("{}");
	const [httpStatusMessage, setHttpStatusMessage] = useState("");
	const [response, setResponse] = useState({ headers: "{}", body: "{}" });
	const [httpStatusCode, setHttpStatusCode] = useState(null);

	useEffect(() => {
		getApiTypes(setApiTypes);
		getRelayEndpoints(setApiEndpoints);
	}, []);

	const HTTP_STATUS_MESSAGES = {
		200: "OK",
		201: "Created",
		202: "Accepted",
		204: "No Content",
		400: "Bad Request",
		401: "Unauthorized",
		403: "Forbidden",
		404: "Not Found",
		405: "Method Not Allowed",
		408: "Request Timeout",
		409: "Conflict",
		500: "Internal Server Error",
		502: "Bad Gateway",
		503: "Service Unavailable",
		504: "Gateway Timeout",
	};

	const handleSelectType = (event) => {
		const typeId = event.target.value;
		setSelectedType(typeId);
		setSelectedApi(null);
		setFilteredEndpoints(apiEndpoints.filter((api) => api.type_id === typeId));
	};

	const handleSelectApi = (event) => {
		const api = filteredEndpoints.find(
			(item) => item.id === event.target.value
		);
		setSelectedApi(api);
		if (!api) return;

		const headers = api.headers ? JSON.stringify(api.headers, null, 2) : "{}";

		let body = "{}";
		if (api.request) {
			try {
				body = api.content_type.includes("application/xml")
					? formatXML(api.request)
					: JSON.stringify(JSON.parse(api.request), null, 2);
			} catch {
				body = api.request;
			}
		}

		setRequestHeaders(headers);
		setRequestBody(body);
		setResponse({ headers: "{}", body: "{}" });
	};
	const formatXML = (xml) => {
		try {
			xml = xml
				.replace(/^"(.*)"$/, "$1")
				.replace(/\\n/g, "\n")
				.replace(/\\t/g, "\t")
				.replace(/\\"/g, '"')
				.trim();

			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(xml, "application/xml");

			if (xmlDoc.getElementsByTagName("parsererror").length) {
				throw new Error("Invalid XML");
			}

			const serializer = new XMLSerializer();
			let formatted = serializer.serializeToString(xmlDoc);

			let indentLevel = 0;
			return formatted
				.replace(/(>)(<)(\/*)/g, "$1\n$2$3")
				.split("\n")
				.map((line) => {
					if (line.match(/<\/\w/)) indentLevel--;
					const indentedLine = "  ".repeat(indentLevel) + line.trim();
					if (line.match(/<\w[^>]*[^\/]>$/)) indentLevel++;
					return indentedLine;
				})
				.join("\n");
		} catch (error) {
			return xml;
		}
	};
	const formatTextResponse = (text) => {
		try {
			if (text.startsWith("{") || text.startsWith("[")) {
				return JSON.stringify(JSON.parse(text), null, 2);
			}

			if (text.startsWith("<")) {
				return formatXML(text);
			}

			return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t").trim();
		} catch (error) {
			return text;
		}
	};

	const handleSendRequest = () => {
		if (!selectedApi) return;

		let url = selectedApi.url;
		let dataToSend = requestBody;

		if (selectedApi.method === "GET" && requestBody !== "{}") {
			try {
				const parsed = JSON.parse(requestBody);
				const queryString = new URLSearchParams(parsed).toString();
				url += `?${queryString}`;
				dataToSend = null;
			} catch (err) {}
		} else if (selectedApi.content_type.includes("application/json")) {
			try {
				dataToSend = JSON.stringify(JSON.parse(requestBody));
			} catch (err) {}
		}

		let headers = JSON.parse(requestHeaders);
		if (!headers["Content-Type"]) {
			headers["Content-Type"] = selectedApi.content_type || "application/json";
		}

		const payload =
			selectedApi.method === "POST"
				? {
						body: dataToSend,
						headers: headers,
				  }
				: {
						headers: headers,
						data: dataToSend,
				  };

		relayAPI(
			selectedApi.method,
			url,
			payload,
			(response) => {
				const statusCode = response.status || "Unknown";
				const statusMessage =
					HTTP_STATUS_MESSAGES[statusCode] ||
					response.statusText ||
					"Unknown Status";

				setHttpStatusCode(statusCode);
				setHttpStatusMessage(statusMessage);

				const formattedHeaders = JSON.stringify(response.headers, null, 2);
				let formattedBody = response.body;

				if (selectedApi?.content_type.includes("application/xml")) {
					formattedBody = formatXML(response.body);
				} else if (selectedApi?.content_type.includes("application/json")) {
					try {
						formattedBody = JSON.stringify(response.body, null, 2);
					} catch {
						formattedBody = response.body;
					}
				}

				setResponse({ headers: formattedHeaders, body: formattedBody });
			},
			(error) => {
				const statusCode = error?.status ?? "Error";
				const statusMessage =
					HTTP_STATUS_MESSAGES[statusCode] ||
					error?.response?.statusText ||
					"Request Failed";

				setHttpStatusCode(statusCode);
				setHttpStatusMessage(statusMessage);

				setResponse({
					headers: JSON.stringify(error.headers, null, 2),
					body: JSON.stringify(error.body, null, 2),
				});
			}
		);
	};

	return (
		<Box
			sx={{
				height: "75vh",
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
				p: 2,
			}}
		>
			<Grid container spacing={2} alignItems="flex-end" sx={{ mb: 2 }}>
				<Grid item xs={4}>
					<Box sx={{ mb: 1 }}>
						<Typography variant="body1" sx={{ fontWeight: "bold" }}>
							API Category
						</Typography>
					</Box>
					<FormControl fullWidth>
						<Select value={selectedType} onChange={handleSelectType}>
							{apiTypes.map((apiType) => (
								<MenuItem key={apiType.id} value={apiType.id}>
									{apiType.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={4}>
					<Box sx={{ mt: 2, mb: 1 }}>
						<Typography variant="body1" sx={{ fontWeight: "bold" }}>
							API Name
						</Typography>
					</Box>
					<FormControl fullWidth>
						<Select
							value={selectedApi ? selectedApi.id : ""}
							onChange={handleSelectApi}
						>
							{filteredEndpoints.map((api) => (
								<MenuItem key={api.id} value={api.id}>
									{api.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="contained"
						color="secondary"
						onClick={handleSendRequest}
					>
						Send
					</Button>

					<Box
						sx={{
							ml: 2,
							px: 2,
							py: 1,
							border: "2px solid white",
							borderRadius: "4px",
							color: "white",
							bgcolor:
								httpStatusCode >= 200 && httpStatusCode < 300
									? "#091152"
									: "red",
							minHeight: "40px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							visibility: httpStatusCode !== null ? "visible" : "hidden",
						}}
					>
						{httpStatusCode !== null && (
							<Typography variant="body1">
								{httpStatusCode} {httpStatusMessage}
							</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				{/* Request Panel */}
				<Grid item xs={6}>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
						Request
					</Typography>
					<Tabs
						value={requestTab}
						onChange={(_, newValue) => setRequestTab(newValue)}
					>
						<Tab label="Headers" />
						<Tab label="Body" />
					</Tabs>
					<Editor
						height="calc(100vh - 200px)"
						language={
							selectedApi?.content_type === "application/xml" ? "xml" : "json"
						}
						value={requestTab === 0 ? requestHeaders : requestBody}
						onChange={(newVal) =>
							requestTab === 0
								? setRequestHeaders(newVal || "{}")
								: setRequestBody(
										selectedApi?.content_type === "application/xml"
											? formatXML(newVal || "")
											: newVal || "{}"
								  )
						}
						options={{
							readOnly: true,
							wordWrap: "on",
							scrollBeyondLastLine: false,
							horizontalScrollbarSize: 20,
							verticalScrollbarSize: 20,
							overviewRulerBorder: false,
							lineNumbers: "on",
							minimap: { enabled: true },
							renderWhitespace: "all",
							formatOnPaste: true,
							formatOnType: true,
							automaticLayout: true,
							scrollbar: {
								vertical: "visible",
								horizontal: "visible",
								useShadows: false,
							},
						}}
						theme="vs-dark"
					/>
				</Grid>
				{/* Response Panel */}
				<Grid item xs={6}>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
						Response
					</Typography>
					<Tabs
						value={responseTab}
						onChange={(_, newValue) => setResponseTab(newValue)}
					>
						<Tab label="Headers" />
						<Tab label="Body" />
					</Tabs>

					<Editor
						height="calc(100vh - 200px)"
						language="json"
						value={responseTab === 0 ? response.headers : response.body}
						options={{
							readOnly: true,
							wordWrap: "on",
							scrollBeyondLastLine: false,
							horizontalScrollbarSize: 20,
							verticalScrollbarSize: 20,
							overviewRulerBorder: false,
							lineNumbers: "on",
							minimap: { enabled: true },
							renderWhitespace: "all",
							automaticLayout: true,
							formatOnPaste: true,
							formatOnType: true,
							scrollbar: {
								vertical: "visible",
								horizontal: "visible",
								useShadows: false,
							},
						}}
						theme="vs-dark"
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default ApiTester;
