import { useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useUserContext } from "./contexts/userContext";
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import SaleOrders from "./scenes/saleOrders/SaleOrders";
import WorkOrders from "./scenes/workOrders/WorkOrders";
import WorkFlows from "./scenes/workFlows/WorkFlows";
import Wizard from "./scenes/wizard/Wizard";
import Events from "./scenes/eventsPage/events";
import Logs from "./scenes/logs/Logs.jsx";
import Administration from "./components/Administration/Administration.jsx";
import Flow from "./scenes/dagDesignerTool/DagDesignerTool";
import DagTemplates from "./scenes/dagDesignerTool/DagTemplates";
import DataView from "./scenes/dataView/dataView.jsx";

function App() {
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);

	const userContext = useUserContext();
	const user = userContext?.getUser ? userContext.getUser() : null;

	const isDataViewer = user.hasRole("ngom-dataviewer"); 
	


	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ConfirmProvider>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<div className="app">
							<BrowserRouter>
								<Sidebar isSidebar={isSidebar} />
								<main className="content">
									<Topbar setIsSidebar={setIsSidebar} />
									<Routes>
										{isDataViewer ? (
											<>
												<Route path="/dataview" element={<DataView />} />
												<Route path="*" element={<Navigate to="/dataview" />} />
											</>
										) : (
											<>
												<Route path="/" element={<Dashboard />} />
												<Route path="/salesorders" element={<SaleOrders />} />
												<Route path="/workorders" element={<WorkOrders />} />
												<Route path="/workflows" element={<WorkFlows />} />
												<Route path="/wizard" element={<Wizard />} />
												<Route path="/events" element={<Events />} />
												<Route path="/logs" element={<Logs />} />
												<Route
													path="/administration"
													element={<Administration />}
												/>
												<Route path="/dataview" element={<DataView />} />
												<Route
													path="/dag_templates"
													element={<DagTemplates />}
												/>
												<Route
													path="/dag_templates/:dagName"
													element={<Flow />}
												/>
											</>
										)}
									</Routes>
								</main>
							</BrowserRouter>
						</div>
					</LocalizationProvider>
				</ConfirmProvider>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
