import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ReorderIcon from "@mui/icons-material/Reorder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ListIcon from "@mui/icons-material/List";
import { HistoryOutlined, WorkOutline } from "@mui/icons-material";
import SchemaIcon from "@mui/icons-material/Schema";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useUserContext } from "../../contexts/userContext";

const Item = ({ title, to, icon }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const location = useLocation();

	return (
		<MenuItem
			active={location.pathname === to}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => {}}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const userContext = useUserContext();
	const user = userContext.getUser();

	const isDataViewer = user.hasRole("ngom-dataviewer"); 

	return (
		<Box
			sx={{
				"& .pro-sidebar-inner": {
					background: `${colors.primary[400]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "10px 35px 5px 5px !important",
				},
				"& .pro-inner-item:hover": {
					color: "#d54800 !important",
				},
				"& .pro-menu-item.active": {
					color: "#ff7620 !important",
				},
				"& .pro-menu-item:active": {
					color: "#b23800 !important",
				},
			}}
		>
			<ProSidebar collapsed={isCollapsed} width="240px">
				<Menu iconShape="square">
					{/* LOGO AND MENU ICON */}
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: "5px 0 10px 0",
							color: colors.grey[100],
						}}
					>
						{!isCollapsed && (
							<Box display="flex" alignItems="center" ml="30px">
								<Box mb="15px">
									<Typography
										variant="h3"
										color={colors.grey[100]}
										fontWeight="bold"
										sx={{ m: "10px 0 0 0" }}
									>
										NGOM
									</Typography>
								</Box>
								<Box flex="1"></Box>{" "}
								{/* Spacer to push the IconButton to the right */}
								<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
									<MenuOutlinedIcon />
								</IconButton>
							</Box>
						)}
					</MenuItem>
					<Box paddingLeft={isCollapsed ? undefined : "10%"}>
						{isDataViewer ? (
							<Item title="Data View" to="/dataview" icon={<BarChartIcon />} />
						) : (
							<>
								<Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} />
								<Item
									title="Sales Orders"
									to="/salesorders"
									icon={<ReceiptIcon />}
								/>
								<Item
									title="Work Orders"
									to="/workorders"
									icon={<WorkOutline />}
								/>
								<Item
									title="Workflows"
									to="/workflows"
									icon={<AccountTreeIcon />}
								/>
								<Item title="Wizard" to="/wizard" icon={<AutoFixHighIcon />} />
								<Item title="Events" to="/events" icon={<HistoryOutlined />} />
								<Item title="Logs" to="/logs" icon={<ListIcon />} />
								{user && user.hasRole("ngom-administrator") && (
									<Item
										title="Administration"
										to="/administration"
										icon={<AdminPanelSettingsIcon />}
									/>
								)}
								<Item
									title="Data View"
									to="/dataview"
									icon={<BarChartIcon />}
								/>
								{user && user.hasRole("ngom-businessuser") && (
									<Item
										title="Dag Templates"
										to="/dag_templates"
										icon={<SchemaIcon />}
									/>
								)}
							</>
						)}
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
