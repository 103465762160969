import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserContextProvider } from "./contexts/userContext"; 
import UserService from "./services/UserService";

const renderApp = () => {
	const root = ReactDOM.createRoot(document.getElementById("root"));

	root.render(
		<React.StrictMode>
			<UserContextProvider>
				{" "}
				<App />
			</UserContextProvider>
		</React.StrictMode>
	);
};

UserService.initKeycloak(renderApp);
