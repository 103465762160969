import React, { useState, useEffect } from "react";
import {
	Box,
	Select,
	MenuItem,
	Typography,
	Grid,
	IconButton,
	Tooltip,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Refresh, ExpandMore } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useHttpService from "../../customHooks/useHttpService";
import JsonSchemaBox from "../../components/Common/JsonSchemaBox.jsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData"; 
import customParseFormat from "dayjs/plugin/customParseFormat";  

const apiGetDashboardPath = "/api/scalar/grafana/Dashboard";
const apiUrlGetDashboard = new URL(
	apiGetDashboardPath,
	process.env.NODE_ENV === "production"
		? window.location.origin + process.env.REACT_APP_PROD_API_BASE_PATH
		: process.env.REACT_APP_DEV_API_BASE_URL +
		  process.env.REACT_APP_DEV_API_BASE_PATH
);

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);


const browserLocale = navigator.language || "en-US";

const localeFormats = {
	"en-US": "MM/DD/YYYY HH:mm", // US Format
	"it-IT": "DD/MM/YYYY HH:mm", // Italian Format
	"fr-FR": "DD/MM/YYYY HH:mm", // French Format
	"de-DE": "DD.MM.YYYY HH:mm", // German Format
	"es-ES": "DD/MM/YYYY HH:mm", // Spanish Format
	default: "YYYY-MM-DD HH:mm", // Fallback Format
};

const dateFormat = localeFormats[browserLocale] || localeFormats["default"];

const DataView = () => {
	const httpService = useHttpService();
	const [filters, setFilters] = useState({});
	const [jsonSchema, setJsonSchema] = useState({});
	const [dashboards, setDashboards] = useState([]);
	const [selectedDashboard, setSelectedDashboard] = useState(null);
	const [iframeKey, setIframeKey] = useState(0);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [fromDate, setFromDate] = useState(dayjs().utc().subtract(24, "hour"));
	const [toDate, setToDate] = useState(dayjs().utc());

	const fetchDashboards = () => {
		setIsLoading(true);
		setIsError(false);

		httpService.getAPI(
			apiUrlGetDashboard,
			[],
			(response) => {
				const dashboardsData = Array.isArray(response) ? response : [];
				const visibleDashboards = dashboardsData.filter(
					(dashboard) => dashboard.is_visible
				);
				setDashboards(visibleDashboards);
				setIsLoading(false);
			},
			() => {},
			setIsLoading,
			() => {},
			setIsError
		);
	};

	const handleFilterChange = (newFilters) => {
		setFilters(newFilters);
		setIframeKey((prevKey) => prevKey + 1);
	};

	const getFilteredDashboardUrl = (baseUrl) => {
		if (!baseUrl) return "";

		let url = new URL(baseUrl, window.location.origin);

		if (!url.searchParams.has("orgId")) {
			url.searchParams.set("orgId", "1");
		}

		url.searchParams.delete("kiosk");
		url.searchParams.delete("theme");
		url.searchParams.delete("hideControls");

		url.searchParams.set("kiosk", "1");
		url.searchParams.set("hideControls", "1");
		url.searchParams.set("theme", "light");

		if (fromDate) url.searchParams.set("from", dayjs(fromDate).utc().valueOf());
		if (toDate) url.searchParams.set("to", dayjs(toDate).utc().valueOf());

		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				url.searchParams.set(key, value);
			}
		});

		return url.toString();
	};

	useEffect(() => {
		fetchDashboards();
	}, []);

	const handleDashboardChange = (event) => {
		const selectedUrl = event.target.value;
		setSelectedDashboard(selectedUrl);

		const selected = dashboards.find((d) => d.url === selectedUrl);
		if (selected && selected.json_schema) {
			setJsonSchema(selected.json_schema);
		} else {
			setJsonSchema({});
		}
	};

	const handleRefresh = () => {
		setIframeKey((prevKey) => prevKey + 1);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "calc(100vh - 64px)",
					overflow: "hidden",
					p: 2,
				}}
			>
				<Typography variant="h6" gutterBottom>
					Grafana Dashboard Viewer
				</Typography>
				<Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
					<Grid item xs={12} md={4}>
						<Select
							fullWidth
							value={selectedDashboard || ""}
							onChange={handleDashboardChange}
							displayEmpty
						>
							{dashboards.length > 0 ? (
								dashboards.map((dashboard) => (
									<MenuItem key={dashboard.id} value={dashboard.url}>
										{dashboard.name}
									</MenuItem>
								))
							) : (
								<MenuItem disabled>No dashboards available</MenuItem>
							)}
						</Select>
					</Grid>

					<Grid item>
						<DateTimePicker
							label="From Date"
							value={fromDate ? dayjs(fromDate).local() : null}
							onChange={(newValue) =>
								setFromDate(newValue ? dayjs(newValue).utc() : null)
							}
							renderInput={(params) => <TextField {...params} />}
							format={dateFormat}
						/>
					</Grid>

					<Grid item>
						<DateTimePicker
							label="To Date"
							value={toDate ? dayjs(toDate).local() : null} 
							onChange={(newValue) =>
								setToDate(newValue ? dayjs(newValue).utc() : null)
							}
							renderInput={(params) => <TextField {...params} />}
							format={dateFormat}
						/>
					</Grid>

					<Grid item>
						<Tooltip arrow title="Refresh">
							<IconButton onClick={handleRefresh}>
								<Refresh />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				{selectedDashboard &&
					jsonSchema &&
					Object.keys(jsonSchema).length > 0 && (
						<Accordion
							sx={{ mb: 2, backgroundColor: "#3d3d3d", color: "white" }}
							defaultExpanded={false}
						>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								sx={{ color: "white" }}
							>
								<Typography>Advanced Filters</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<JsonSchemaBox
									schema={jsonSchema}
									onChange={handleFilterChange}
								/>
							</AccordionDetails>
						</Accordion>
					)}
				{isLoading && (
					<Typography color="primary">Loading dashboards...</Typography>
				)}
				{isError && (
					<Typography color="error">Error loading dashboards</Typography>
				)}

				{selectedDashboard && !isError && (
					<Box
						sx={{
							flexGrow: 1,
							border: "1px solid #ccc",
							borderRadius: 2,
							overflow: "hidden",
						}}
					>
						<iframe
							key={iframeKey}
							src={getFilteredDashboardUrl(selectedDashboard)}
							width="100%"
							height="100%"
							title="Grafana Dashboard"
							style={{ border: "none" }}
						></iframe>
					</Box>
				)}
			</Box>
		</LocalizationProvider>
	);
};

export default DataView;
